body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e3e2e2;
  min-height: 100vh;
  color: #000000;
  padding: 35px;
}

.block {
  background-color: #ffffff;
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 70px;
  border-radius: 25px;
}

.title {
  text-align: center;
  font-size: 30px;
  padding-bottom: 20px;
  color: #000000;
}

.main .form-label {
  color: #5C5C5C;
}

.main .form-control {
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  transition: all 0.2s ease-in-out;
  color: #000000;
  font-size: 14px;
  line-height: 24px;
}

.main .form-control:active,
.main .form-control:focus {
  border-color: #00dfed;
  box-shadow: none;
  outline: none;
  color: #000000;
}

.main .btn.btn-primary {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 72px;
  transition: all 0.2s ease-in-out;
}

.main .btn.btn-primary:hover,
.main .btn.btn-primary:active {
  background-color: #00dfed;
  border-color: #00dfed;
  color: #000000;
}

.main .btn.btn-primary:disabled {
  background-color: #D7DADC;
  border-color: #D7DADC;
  color: #B3B3B3;
}

.form {
  padding-bottom: 20px;
}

.code {
  display: block;
  background-color: rgb(40, 42, 54);
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  color: #00dfed;
}

.app-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}